import { API, Amplify, Auth } from "aws-amplify";
import { createContext,useState,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import awsConfig from "../aws-config";


const AuthContext=createContext();
export default AuthContext;


var amplify=Amplify;

export const AuthContextProvider = ({children}) => {
  // Amplify.configure(awsConfig);
  const location=useLocation();
    const navigate=useNavigate();
    const [AuthMessage,setAuthMessage]=useState({IsSuccess:false,Message:null});
    const [UserName,setUserName]=useState(null);
    const [UserId,setUserId]=useState(null);
    const [IsAuthenticated,setIsAuthenticated]=useState(false);
    const [SinginLoader,setSinginLoader]=useState(false);
    const [SignUpLoader,setSignUpLoader]=useState(false);
    const [ResendOTPLoader,setResendOTPLoader]=useState(false);
    const [EmailConfirmLoader,setEmailConfirmLoader]=useState(false);
    const [Email,setEmail]=useState("");
    const [ForgotPasswordLoader,setForgotPasswordLoader]=useState(false);
    const [ResendForgotPasswordOTPLoader,setResendForgotPasswordOTPLoader]=useState(false);
    const [ResetPasswordLoader,setResetPasswordLoader]=useState(false);
    const [ChangePasswordLoader,setChangePasswordLoader]=useState(false);
    const [ChangePasswordMessage,setChangePasswordMessage]=useState({IsSuccess:false,Message:null});
    const [IsAuthenticatedByIdps,setIsAuthenticatedByIdps]=useState(false);
    const [IsSpeak2User,setIsSpeak2User]=useState(false);
    // const [UserProfile,setUserProfile]=useState(null);
    // const [GetUserProfileMessage,setGetUserProfileMessage]=useState("");
    // const [UpdateEmailLoader,setUpdateEmailLoader]=useState(false);
    // const [UpdateEmailMessage,setUpdateEmailMessage]=useState({IsSuccess:false,Message:null});
    // const [ShowConfirmEmailForm,setShowConfirmEmailForm]=useState(false);
    // const [ShowEmailUpdateForm,setShowEmailUpdateForm]=useState(false);
    // const [UpdateEmail,setUpdateEmail]=useState("");
    // const [ResendOTPUpdatedEmailLoader,setResendOTPUpdatedEmailLoader]=useState(false);
  // useEffect(()=>{
  //   getUserProfile();
  // },[])

    const checkUserAuthenticated = async () => {
      
      try {
        // Get the currently authenticated user object
        const user = await Auth.currentAuthenticatedUser();
      
        const userId=user.signInUserSession.idToken.payload.sub;
        if(user.signInUserSession.idToken.payload.identities)
        {
          // alert("SOcial Identity provider is "+JSON.stringify(user.signInUserSession.idToken.payload.identities))
          setIsAuthenticatedByIdps(true);
        }
        else
        {
          setIsAuthenticatedByIdps(false);
          // alert("No Social Identity provider")
        }
        // Redirect the user to the specified URL
        setAuthMessage({IsSuccess:true,Message:"Authenticated Sucessfully"});
        setUserName(UserName);
        setUserId(userId);
        setIsAuthenticated(true);
        navigate('/designs');
      } catch (error) {
        console.log('User is not authenticated', error);
        setAuthMessage({IsSuccess:false,Message:"Something went Wrong please re-try"});
        navigate('/');
        // Redirect the user to the sign-in page
      }
      
    };
    
    useEffect(()=>{
      const queryParams = new URLSearchParams(location.search);
      const isRedirectedSpeak2User = queryParams.get("speak2-user");
      if(isRedirectedSpeak2User==='true')
      {
        awsConfig.userPoolId=process.env.REACT_APP_SPEAK2_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_SPEAK2_USER_POOL_CLIENT_ID;
        awsConfig.identityPoolId=process.env.REACT_APP_SPEAK2_IDENTITY_POOL_ID
        awsConfig.oauth.domain="speak2.auth.us-east-1.amazoncognito.com";
        awsConfig.oauth.scope=['email', 'openid', 'phone']
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/redirect", "/speak2-redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        // console.log("Let me SIgn In i am comming from speak2 site")
        // const OAuthToken = queryParams.get("code");
        // console.log("TOken iss+"+OAuthToken);
        Auth.federatedSignIn()
      }
      else
      {
      const wasspeak2user=localStorage.getItem("wasSpeak2User");
      // alert("User selection was"+wasspeak2user);
      if(wasspeak2user==="true")
      {
        awsConfig.userPoolId=process.env.REACT_APP_SPEAK2_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_SPEAK2_USER_POOL_CLIENT_ID;
        awsConfig.identityPoolId=process.env.REACT_APP_SPEAK2_IDENTITY_POOL_ID
        awsConfig.oauth.domain="speak2.auth.us-east-1.amazoncognito.com";
        awsConfig.oauth.scope=['email', 'openid', 'phone']
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/redirect", "/speak2-redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        // alert("User was speak2 when left the tab");
        setIsSpeak2User(true);
      }
      else
      {
        awsConfig.userPoolId=process.env.REACT_APP_PRNTYR_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_PRNTYR_USER_POOL_CLIENT_ID;
        awsConfig.oauth.domain="prntyrapp.auth.us-east-1.amazoncognito.com";
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/speak2-redirect","/redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        // alert("User was Prntyr when left the tab"+awsConfig.userPoolId+"client id "+ awsConfig.userPoolWebClientId);
        setIsSpeak2User(false);
      }
    }
      IsUserAUthenticated();
  },[])
  // handle Is SPeak2 or Prntyr User Selection
  const handleIsSpeak2OrPrntyrUserSelection=(value)=>{
      if(value===true)
      {
        
        awsConfig.userPoolId=process.env.REACT_APP_SPEAK2_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_SPEAK2_USER_POOL_CLIENT_ID;
        awsConfig.identityPoolId=process.env.REACT_APP_SPEAK2_IDENTITY_POOL_ID
        awsConfig.oauth.domain="speak2.auth.us-east-1.amazoncognito.com";
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/redirect","/speak2-redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        localStorage.setItem("wasSpeak2User",true);
        // alert("Speak2 Pool credentials needs to be added"+awsConfig.userPoolId);
      }
      else
      {
        awsConfig.userPoolId=process.env.REACT_APP_PRNTYR_USER_POOL_ID;
        awsConfig.userPoolWebClientId=process.env.REACT_APP_PRNTYR_USER_POOL_CLIENT_ID;
        awsConfig.oauth.domain="prntyrapp.auth.us-east-1.amazoncognito.com";
        const Speak2Redirect=awsConfig.oauth.redirectSignIn.replace("/speak2-redirect","/redirect")
        awsConfig.oauth.redirectSignIn=Speak2Redirect;
        amplify.configure(awsConfig);
        localStorage.setItem("wasSpeak2User",false);
        // alert("Prntyr User Pool credentials needs to be added"+awsConfig.userPoolId);
      }
      setIsSpeak2User(value);
  }


  // check user is authenticated if user re-loads the page. 
  const IsUserAUthenticated=async()=>{
    try {
      // Get the currently authenticated user object
      const user = await Auth.currentAuthenticatedUser();
      // console.log("Current Authenticated "+JSON.stringify(user));
      // const idToken = user.signInUserSession.idToken.jwtToken;
      // console.log('Access token:', idToken);
      const userId=user.signInUserSession.idToken.payload.sub;
      if(user.signInUserSession.idToken.payload.identities)
      {
        // alert("SOcial Identity provider is "+JSON.stringify(user.signInUserSession.idToken.payload.identities))
        setIsAuthenticatedByIdps(true);
      }
      else
      {
        setIsAuthenticatedByIdps(false);
        // alert("No Social Identity provider")
      }
      // Redirect the user to the specified URL
      setAuthMessage({IsSuccess:true,Message:"Authenticated Sucessfully"});
      if(localStorage.getItem("wasSpeak2User")==='true')
      {
        setUserName(user.username);
      }
      else
      {
        setUserName(user.signInUserSession.idToken.payload.email);
      }
      
      setUserId(userId);
      setIsAuthenticated(true);
      navigate('/designs');
    } catch (error) {
      console.log('User is not authenticated', error);
      // setAuthMessage({IsSuccess:false,Message:"Something went Wrong please re-try"});
      navigate('/');
      // Redirect the user to the sign-in page
    }
  }
  

  // handle Speak2 Login 
      const  handleSpeak2Login=async(e)=>{
        e.preventDefault();
        setSinginLoader(true);
        const UserName=e.target.username.value;
        const Password=e.target.password.value;
        setUserName(UserName);
        try {
            // Sign in the user with Cognito
            const user = await Auth.signIn(UserName, Password);
            // Handle successful sign-in
         
            // const newuser = await Auth.completeNewPassword(
            //   user, // User object obtained from Auth.signIn() or Auth.currentAuthenticatedUser()
            //   "Toor@1122",
            //   // Any additional user attributes can be provided here if needed.
            // );
            // console.log('Sign in successful:', JSON.stringify(user));
            setAuthMessage({IsSuccess:true,Message:"Authenticated Sucessfully"});
            const userId=user.signInUserSession.idToken.payload.sub;
            setUserId(userId);
            setIsAuthenticated(true);
            navigate('/designs');
          } catch (error) {
            // Handle error during sign-in
            if (error.code === "NotAuthorizedException") {
              setAuthMessage({IsSuccess:false,Message:error.message});
            } else if (error.code === "UserNotFoundException") {
              // alert("User not found");
              setAuthMessage({IsSuccess:false,Message:"User not found please Signup."});
            } else if (error.code === "UserNotConfirmedException") {
              navigate("/confirmemail")
            } else if (error.code === "UnexpectedLambdaException") {
              // alert("An unexpected error occurred. Please try again later.");
              setAuthMessage({IsSuccess:false,Message:"An unexpected error occurred. Please try again later."});
            } else {
              // alert("An error occurred. Please try again later.");
              setAuthMessage({IsSuccess:false,Message:"An error occurred. Please try again later."});
            }
            console.error('Error during sign in:', error);
          }

        setSinginLoader(false); 
        // console.log("User name is "+UserEmail+"and password is"+Password);
    }




    // handle Login 
    const  handleLogin=async(e)=>{
        e.preventDefault();
        setSinginLoader(true);
        const UserEmail=e.target.email.value;
        const Password=e.target.password.value;
        setUserName(UserEmail);
        try {
            // Sign in the user with Cognito
            const user = await Auth.signIn(UserEmail, Password);
            // Handle successful sign-in
         
            // const newuser = await Auth.completeNewPassword(
            //   user, // User object obtained from Auth.signIn() or Auth.currentAuthenticatedUser()
            //   "Toor@1122",
            //   // Any additional user attributes can be provided here if needed.
            // );
            // console.log('Sign in successful:', JSON.stringify(user));
            setAuthMessage({IsSuccess:true,Message:"Authenticated Sucessfully"});
            const userId=user.signInUserSession.idToken.payload.sub;
            setUserId(userId);
            setIsAuthenticated(true);
            navigate('/designs');
          } catch (error) {
            // Handle error during sign-in
            if (error.code === "NotAuthorizedException") {
              setAuthMessage({IsSuccess:false,Message:error.message});
            } else if (error.code === "UserNotFoundException") {
              // alert("User not found");
              setAuthMessage({IsSuccess:false,Message:"User not found please Signup."});
            } else if (error.code === "UserNotConfirmedException") {
              navigate("/confirmemail")
            } else if (error.code === "UnexpectedLambdaException") {
              // alert("An unexpected error occurred. Please try again later.");
              setAuthMessage({IsSuccess:false,Message:"An unexpected error occurred. Please try again later."});
            } else {
              // alert("An error occurred. Please try again later.");
              setAuthMessage({IsSuccess:false,Message:"An error occurred. Please try again later."});
            }
            console.error('Error during sign in:', error);
          }

        setSinginLoader(false); 
        // console.log("User name is "+UserEmail+"and password is"+Password);
    }


    // handle logout
    const handleLogOut=async()=>{
      if(IsSpeak2User===true)
      {
        // localStorage.clear();
        for (const key in localStorage) {
          if (localStorage.hasOwnProperty(key) && key !== "wasSpeak2User") {
            localStorage.removeItem(key);
          }
        }
        setIsAuthenticated(false);
        setAuthMessage({IsSuccess:false,Message:null});
        navigate('/');
        return;
      }
        try {
            await Auth.signOut();
            console.log('User signed out');
            setIsAuthenticated(false);
            setAuthMessage({IsSuccess:false,Message:null});
            navigate('/');
            
          } catch (error) {
            console.log('Error signing out user', error);
            
          }
       
    }

    const handleSignUp=async(event)=>{
        event.preventDefault();
        setSignUpLoader(true);
        const formData = new FormData(event.target);
        try {
          const {  email, password } = Object.fromEntries(formData.entries());
          console.log("User Name "+email+"Email is "+email+"Passwords is"+password);
          setUserName(email);
          const username=email;
          // Sign up the user with Cognito
          const result = await Auth.signUp({
            username,
            password,
            attributes: {
              email, // Add any additional attributes here
            },
          });
      
          // Handle successful signup
          // console.log('Sign up successful:', JSON.stringify(result));
          navigate('/confirmemail')
        } catch (error) {
          // Handle error during signup
          console.error('Error during sign up:', error);
          if (error.code === 'InvalidParameterException') {
            // alert('One or more of the parameters provided is invalid.');
            setAuthMessage({IsSuccess:false,Message:"Something went wrong... please retry"});
          } else if (error.code === 'UsernameExistsException') {
            // alert('A user with the same username already exists in the user pool.');
            setAuthMessage({IsSuccess:false,Message:"User already Exists with Same Email.. please change Email"});
          } else if (error.code === 'AliasExistsException') {
            // alert('A user with the same email or phone number already exists in the user pool.');
            setAuthMessage({IsSuccess:false,Message:"User already Exists with Same Email"});
          } else if (error.code === 'CodeDeliveryFailureException') {
            // alert('The delivery of the verification code failed.');
            setAuthMessage({IsSuccess:false,Message:"The delivery of the verification code failed.. please retry"});
          } else if (error.code === 'UnexpectedLambdaException') {
            // alert('An unexpected error occurred during the signup process.');
            setAuthMessage({IsSuccess:false,Message:"An unexpected error occurred during the signup process"});
          } else if (error.code === 'UserLambdaValidationException') {
            // alert('A validation error occurred during the signup process.');
            setAuthMessage({IsSuccess:false,Message:"Something went Wrong... please retry."});
          } else if (error.code === 'PasswordResetRequiredException') {
            // alert('The account has a password that is expired or needs to be reset.');
            setAuthMessage({IsSuccess:false,Message:"The account has a password that is expired or needs to be reset."});
          } else if(error.code==="InvalidPasswordException")
          {
            setAuthMessage({IsSuccess:false,Message:error.message});
          }
          else {
            // alert('An unknown error occurred.');
            setAuthMessage({IsSuccess:false,Message:"Something went Wrong... please retry"});
          }
        }
        setSignUpLoader(false);
      }
      
    //   Sign In With Google 
    const signInWithGoogle = async () => {
        try {
          const response = await Auth.federatedSignIn({
            provider: 'Google',
            // OPTIONAL - Additional auth data you want to pass to Cognito
            // authParams: { scopes: ['openid', 'email', 'profile'], prompt: 'consent' },
          });
          console.log("Authenticated Successfully"+JSON.stringify(response));
        } catch (error) {
          console.log('Error signing in with Google', error);
        }
      };

      // Sign In with Amazon
    const signInWithAmazon=async()=>{
      try {
        const response = await Auth.federatedSignIn({
          provider: 'LoginWithAmazon',
          // OPTIONAL - Additional auth data you want to pass to Cognito
          // authParams: { scopes: ['openid', 'email', 'profile'], prompt: 'consent' },
        });
        console.log("Authenticated Successfully"+JSON.stringify(response));
      } catch (error) {
        console.log('Error signing in with Google', error);
      }
    }
    // Sign in With Apple 
    const signInWithApple=async()=>{
      try {
        const response = await Auth.federatedSignIn({
          provider: 'SignInWithApple',
          // OPTIONAL - Additional auth data you want to pass to Cognito
          // authParams: { scopes: ['openid', 'email', 'profile'], prompt: 'consent' },
        });
        console.log("Authenticated Successfully"+JSON.stringify(response));
      } catch (error) {
        console.log('Error signing in with Google', error);
      }
    }
    // Sign In with Facebook
    const signInWithFacebook = async () => {
      try {
        const response = await Auth.federatedSignIn({
          provider: 'Facebook',
          // OPTIONAL - Additional auth data you want to pass to Cognito
          // authParams: { scope: 'public_profile,email', display: 'popup' },
        });
        console.log('Authenticated successfully', response);
      } catch (error) {
        console.log('Error signing in with Facebook', error);
      }
    }; 


    // Email Confirmation
    const confirmEmail=async(e)=>{
      e.preventDefault();
      setEmailConfirmLoader(true);
      let OTP=e.target.OTP.value;
      let Username=e.target.username.value;
      setUserName(Username)
      // alert("Email OTP is "+OTP+" and user name is "+Username);
      try {
        await Auth.confirmSignUp(Username, OTP);
        setAuthMessage({IsSuccess:true,Message:"Email verified successfully... you can login now"});
        navigate("/")
      } catch (error) {
        if (error.code==="CodeMismatchException")
        {
          setAuthMessage({IsSuccess:false,Message:error.message});
        }
        else
        {
          if(error.code==="ExpiredCodeException")
          {
            setAuthMessage({IsSuccess:false,Message:"OTP expired request again and retry"});
          }
        }

        console.log('Error confirming email:', error);
       
        
      }
      setEmailConfirmLoader(false);
    }
      // Resend The OTP 
    const resendOTP=async()=>{
      setResendOTPLoader(true);
      if(UserName===null || UserName==="" )
      {
        setAuthMessage({IsSuccess:false,Message:"please Enter User name "});
        setResendOTPLoader(false);
        return;
      }
      try {
        const response = await Auth.resendSignUp(UserName)
        console.log("OTP sent Successfully"+JSON.stringify(response));
        setAuthMessage({IsSuccess:true,Message:"OTP sent Successfully"});
      } catch (error) {
        console.log('Error Sending the OTP', error);
      }
      setResendOTPLoader(false);
    }

    // handle Username for Resending OTP 
    const handleUserName=(e)=>{
      setUserName(e.target.value);
      if(AuthMessage.Message!==null)
      {
        setAuthMessage({IsSuccess:false,Message:null});
      }
    }

    // handle Email change for Forgot password
    const handleEmailChange=(e)=>{
      setEmail(e.target.value);
    }

    // Forgot Password Get Email OTP 
    const handleForgotPassword=async(e)=>{
      e.preventDefault();
        setForgotPasswordLoader(true);
        try {
         const responce= await Auth.forgotPassword(Email);
          setAuthMessage({IsSuccess:true,Message:"OTP sent Successfully"});
          console.log("Responce of FORGOT password"+JSON.stringify(responce));
          navigate("/resetpassword")
        } catch (error) {
          // alert("can't send OTP "+error.code)
          if (error.code === 'AliasExistsException') {
            // console.error('An alias already exists for this email');
            setAuthMessage({IsSuccess:false,Message:" User exists for this email"});
          } else if (error.code === 'CodeDeliveryFailureException') {
            // console.error('Failed to deliver the verification code');
            setAuthMessage({IsSuccess:false,Message:" Failed to deliver the verification code"});
          } else if (error.code === 'InvalidEmailRoleAccessPolicyException') {
            // console.error('Invalid email template role access policy');
            setAuthMessage({IsSuccess:false,Message:"Invalid Email"});
          } else if (error.code === 'InvalidLambdaResponseException') {
            // console.error('Invalid Lambda response');
            setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
          } else if (error.code === 'InvalidParameterException') {
            // console.error('Invalid parameter');
            setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
          } else if (error.code === 'InvalidSmsRoleAccessPolicyException') {
            // console.error('Invalid SMS template role access policy');
            setAuthMessage({IsSuccess:false,Message:"Something went Wrong... please try again"});
          } else if (error.code === 'LimitExceededException') {
            // console.error('Too many requests for forgot password');
            setAuthMessage({IsSuccess:false,Message:"Too many requests for forgot password try again after some time"});
          } else if (error.code === 'NotAuthorizedException') {
            // console.error('User is not authorized to perform this operation');
            setAuthMessage({IsSuccess:false,Message:"User is not authorized to perform this operation"});
          } else if (error.code === 'ResourceNotFoundException') {
            // console.error('User with this email does not exist');
            setAuthMessage({IsSuccess:false,Message:"User with this email does not exist"});
          } else {
            // console.error('Error sending verification code:', error);
            setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
          }

        }
        setForgotPasswordLoader(false);
    }

    // Resent the OTP for FOgot password
    const handleResendOTPForForgotPassword=async()=>{
      setResendForgotPasswordOTPLoader(true);
      if(Email==="")
      {
        setAuthMessage({IsSuccess:false,Message:"Please Enter Email... and Try Again"});
        setResendForgotPasswordOTPLoader(false);
        return
      }
      try {
        await Auth.forgotPassword(Email);
        setAuthMessage({IsSuccess:true,Message:"OTP sent Successfully"});
      } catch (error) {
       
        setAuthMessage({IsSuccess:false,Message:"can't  send OTP...pleae retry"});
        if (error.code === 'AliasExistsException') {
          // console.error('An alias already exists for this email');
          setAuthMessage({IsSuccess:false,Message:" User exists for this email"});
        } else if (error.code === 'CodeDeliveryFailureException') {
          // console.error('Failed to deliver the verification code');
          setAuthMessage({IsSuccess:false,Message:" Failed to deliver the verification code"});
        } else if (error.code === 'InvalidEmailRoleAccessPolicyException') {
          // console.error('Invalid email template role access policy');
          setAuthMessage({IsSuccess:false,Message:"Invalid Email"});
        } else if (error.code === 'InvalidLambdaResponseException') {
          // console.error('Invalid Lambda response');
          setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
        } else if (error.code === 'InvalidParameterException') {
          // console.error('Invalid parameter');
          setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
        } else if (error.code === 'InvalidSmsRoleAccessPolicyException') {
          // console.error('Invalid SMS template role access policy');
          setAuthMessage({IsSuccess:false,Message:"Something went Wrong... please try again"});
        } else if (error.code === 'LimitExceededException') {
          // console.error('Too many requests for forgot password');
          setAuthMessage({IsSuccess:false,Message:"Too many requests for forgot password"});
        } else if (error.code === 'NotAuthorizedException') {
          // console.error('User is not authorized to perform this operation');
          setAuthMessage({IsSuccess:false,Message:"User is not authorized to perform this operation"});
        } else if (error.code === 'ResourceNotFoundException') {
          // console.error('User with this email does not exist');
          setAuthMessage({IsSuccess:false,Message:"User with this email does not exist"});
        } else {
          // console.error('Error sending verification code:', error);
          setAuthMessage({IsSuccess:false,Message:"SOmething Went Wrong... please try again"});
        }
      }
      setResendForgotPasswordOTPLoader(false);
    }

    // handle reset Password

    const handleResetPassword=async(e)=>{
      e.preventDefault();
      let email=e.target.email.value;
      let OTP=e.target.OTP.value;
      let newpassword=e.target.password.value;
      setResetPasswordLoader(true);
      try {
        await Auth.forgotPasswordSubmit(email, OTP, newpassword);
        setAuthMessage({IsSuccess:true,Message:"Password Reset Successfully... Login Now"});
        navigate("/")
      } catch (error) {
      
        if (error.code === 'CodeMismatchException') {
          // console.error('Verification code does not match');
          setAuthMessage({IsSuccess:false,Message:error.message});
        } else if (error.code === 'ExpiredCodeException') {
          console.error('Verification code has expired');
          setAuthMessage({IsSuccess:false,Message:"Verification code has expired... get code again and retry"});
        } else if (error.code === 'LimitExceededException') {
          console.error('Too many requests for forgot password');
          setAuthMessage({IsSuccess:false,Message:"Too many requests for forgot password"});
        } else if (error.code === 'NotAuthorizedException') {
          console.error('User is not authorized to perform this operation');
          setAuthMessage({IsSuccess:false,Message:"User is not authorized to perform this operation"});
        } else if (error.code === 'ResourceNotFoundException') {
          console.error('User with this email does not exist');
          setAuthMessage({IsSuccess:false,Message:"User with this email does not exist"});
        } else {
          console.error('Error resetting password:', error);
          setAuthMessage({IsSuccess:false,Message:"Something went wrong please try again"});
        }
      }
      setResetPasswordLoader(false)
    }

    // handle Change Password

  const handleChangePassword=async(e)=>{
    e.preventDefault();
    setChangePasswordLoader(true);
    let oldpassword=e.target.oldPassword.value;
    let newpassword=e.target.newPassword.value;
    // alert("Old password is "+oldpassword+" and new password is "+newpassword)
    

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldpassword, newpassword);
      console.log('Password changed successfully');
      setChangePasswordMessage({IsSuccess:true,Message:"Password changed successfully"})
    } catch (error) {
      console.log('Error changing password: ', error);
      if (error.code === 'NotAuthorizedException') {
        // console.log('Not authorized to perform the operation');
        setChangePasswordMessage({IsSuccess:false,Message:"Not authorized to perform the operation"});
      } else if (error.code === 'UserNotFoundException') {
        // console.log('User does not exist in the user pool');
        setChangePasswordMessage({IsSuccess:false,Message:"User does not exist in the user pool"});
      } else if (error.code === 'InvalidParameterException') {
        console.log('Invalid parameter(s) passed to the operation');
        setChangePasswordMessage({IsSuccess:false,Message:"Something went wrong... please retry "});
      } else if (error.code === 'LimitExceededException') {
        // console.log('A limit has been exceeded');
        setChangePasswordMessage({IsSuccess:false,Message:"Too Many Requests to change password try again after some time "});
      } else if (error.code === 'CodeMismatchException') {
        // console.log('Verification code provided does not match the code sent');
        setChangePasswordMessage({IsSuccess:false,Message:"Verification code provided does not match the code sent "});
      } else if (error.code === 'ExpiredCodeException') {
        // console.log('Verification code provided has expired');
        setChangePasswordMessage({IsSuccess:false,Message:"Verification code provided has expired"});
      } else if (error.code === 'InvalidPasswordException') {
        // console.log('New password provided does not meet password policy requirements');
        setChangePasswordMessage({IsSuccess:false,Message:"very week password try adding combination of special characters"});
      } else if (error.code === 'UserNotConfirmedException') {
        console.log('User has not confirmed their account yet');
        setChangePasswordMessage({IsSuccess:false,Message:"User has not confirmed their account yet"});
      } else if (error.code === 'UnexpectedLambdaException') {
        // console.log('Unexpected error occurred while invoking a Lambda function');
        setChangePasswordMessage({IsSuccess:false,Message:"Something went wrong... please retry "});
      } else {
        // console.log('Unknown error occurred: ', error);
        setChangePasswordMessage({IsSuccess:false,Message:"Something went wrong... please retry "});      }
    }
    setChangePasswordLoader(false);
  }

  // Get user Profile 
  // const getUserProfile=async()=>{
  //   try {
  //     const userInfo = await Auth.currentUserInfo();
  //      console.log('User info:', JSON.stringify(userInfo));
  //     // setUserProfile(userInfo);
  //   } catch (error) {
  //     console.error('Error getting user info:', error);
  //     // setGetUserProfileMessage("Something Went Wrong...while fetching Data...try reloading");
  //   }
  // }
  




  /*
  // update user Name 
  const updateUserEmail=async(e)=>{
    e.preventDefault();
    setUpdateEmailLoader(true);
      let email=e.target.email.value;
      // alert("Email is "+email)
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user,{ email: email });
        console.log('Email updated successfully');
        setUpdateEmailMessage({IsSuccess:true,Message:"An OTP has been sent to This Email..Please confirm your email"})
        setShowConfirmEmailForm(true);
        setShowEmailUpdateForm(false);
      } catch (error) {
        console.log('Error updating username:', error);
        if (error.code==="NotAuthorizedException") {
          console.log('User not authorized:', error);
        } else if (error.code==="UserNotFoundException") {
          console.log('User not found:', error);
        } else if (error.code==="CodeDeliveryFailureException") {
          console.log('Code delivery failed:', error);
        } else if (error.code==="CodeMismatchException") {
          console.log('Code mismatch:', error);
        } else if (error.code==="ExpiredCodeException") {
          console.log('Code expired:', error);
        } else if (error.code==="InvalidParameterException") {
          console.log('Invalid parameter:', error);
        } else if (error.code==="InvalidPasswordException") {
          console.log('Invalid password:', error);
        } else if (error.code==="LimitExceededException") {
          console.log('Limit exceeded:', error);
        } else if (error.code==="UserLambdaValidationException") {
          console.log('Lambda validation error:', error);
        } else if (error.code==="AliasExistsException") {
          console.log('Alias already exists:', error);
        } else if (error.code==="InvalidLambdaResponseException") {
          console.log('Invalid Lambda response:', error);
        } else if (error.code==="TooManyRequestsException") {
          console.log('Too many requests:', error);
        } else {
          console.log('Unknown error:', error);
        }
      }
      setUpdateEmailLoader(false);
  }
*/
/*
  // verify updated Email 
  const verifiyUpdatedEmail=async(e)=>{
    e.preventDefault();
    setUpdateEmailMessage({IsSuccess:null,Message:null});
    setUpdateEmailLoader(true);
    const verificationCode = e.target.OTP.value; // replace with actual verification code
    const email = e.target.email.value; // replace with actual attribute to verify
    try {
      const params = {}; // additional parameters, if needed
      // const user = await Auth.currentAuthenticatedUser();
      const verifiedemail=await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode, params);
      
      console.log('Verification successful'+verifiedemail);
      setUpdateEmailMessage({IsSuccess:true,Message:"Email Verified Successfully"});
      // getUserProfile();
    } catch (error) {
      if (error.code === 'CodeMismatchException') {
        // console.log('Invalid verification code');
        setUpdateEmailMessage({IsSuccess:false,Message:"Invalid verification code"});
      } else if (error.code === 'LimitExceededException') {
        // console.log('Verification attempts exceeded');
        setUpdateEmailMessage({IsSuccess:false,Message:"Verification attempts exceedede...please try again After Some Time"});
      } else if (error.code === 'ExpiredCodeException') {
        // console.log('Verification code has expired');
        setUpdateEmailMessage({IsSuccess:false,Message:"Verification code has expired...Get the New Code and Try Again"});
      } else if (error.code === 'NotAuthorizedException') {
        // console.log('User is not authorized to perform this action');
        setUpdateEmailMessage({IsSuccess:false,Message:"You are not  authorized to perform this action... try re-login"});
      } else if (error.code === 'UserNotFoundException') {
        // console.log('User not found');
        setUpdateEmailMessage({IsSuccess:false,Message:"Invalid Email"});
      } else {
        console.log('Error verifying attribute:', error.message);
        setUpdateEmailMessage({IsSuccess:false,Message:"Something Went Wrong please try again"});
      }
    }
    setUpdateEmailLoader(false);
  }
  */

  /*
  // Resend Email for updated User Email
  const resendOTPToUpdatedEmail=async()=>{
    setResendOTPUpdatedEmailLoader(true);
    if(UpdateEmail==="")
    {
      setUpdateEmailMessage({IsSuccess:false,Message:"Please enter email and retry"});
      setResendOTPUpdatedEmailLoader(false);
      return
    } 
    try { 
    // await Auth.resendUserAttributeConfirmationCode(UpdateEmail);
    await Auth.verifyCurrentUserAttribute('email',{resend:true,email: UpdateEmail})
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { email: UpdateEmail });
    console.log('Confirmation code resent successfully');
    setUpdateEmailMessage({IsSuccess:true,Message:"OTP  resent successfully...check your email"});
  } catch (error) {
    if (error.code === 'LimitExceededException') {
      console.log('Attempt limit exceeded, please try after some time.');
      setUpdateEmailMessage({IsSuccess:false,Message:"Attempt limit exceeded, please try after some time."});
    } else if (error.code === 'UserNotFoundException') {
      // console.log('The user does not exist.');
      setUpdateEmailMessage({IsSuccess:false,Message:"User Does Not exists"});
    } else if (error.code === 'CodeDeliveryFailureException') {
      // console.log('Failed to deliver the code, please retry.');
      setUpdateEmailMessage({IsSuccess:false,Message:"Failed to deliver the code, please retry."});
    } else if (error.code === 'InvalidParameterException') {
      // console.log('Please provide a valid email.');
      setUpdateEmailMessage({IsSuccess:false,Message:"Please provide a valid email."});
    } else if (error.code === 'UserNotConfirmedException') {
      // console.log('The user is not confirmed.');
      setUpdateEmailMessage({IsSuccess:false,Message:"Users Cant resend OTP.. Your Existing email is not verified verify it first and then try again."});
    } else {
      console.log('An error occurred while resending confirmation code:', error);
      setUpdateEmailMessage({IsSuccess:false,Message:"Something went Wrong...please try again"});
    }
  }
  setResendOTPUpdatedEmailLoader(false);
  }
  */
    let ContextData={
        AuthMessage:AuthMessage,
        IsAuthenticated:IsAuthenticated,
        UserName:UserName,
        UserId:UserId,
        SinginLoader:SinginLoader,
        SignUpLoader:SignUpLoader,
        ResendOTPLoader:ResendOTPLoader,
        EmailConfirmLoader:EmailConfirmLoader,
        Email:Email,
        ForgotPasswordLoader:ForgotPasswordLoader,
        ResendForgotPasswordOTPLoader:ResendForgotPasswordOTPLoader,
        ResetPasswordLoader:ResetPasswordLoader,
        ChangePasswordLoader:ChangePasswordLoader,
        ChangePasswordMessage:ChangePasswordMessage,
        IsAuthenticatedByIdps:IsAuthenticatedByIdps,
        IsSpeak2User:IsSpeak2User,
        setIsSpeak2User:setIsSpeak2User,
        handleIsSpeak2OrPrntyrUserSelection:handleIsSpeak2OrPrntyrUserSelection,
        handleSpeak2Login:handleSpeak2Login,
        // UserProfile:UserProfile,
        // GetUserProfileMessage:GetUserProfileMessage,
        // UpdateEmailLoader:UpdateEmailLoader,
        // UpdateEmailMessage:UpdateEmailMessage,
        // ShowConfirmEmailForm:ShowConfirmEmailForm,
        // ShowEmailUpdateForm:ShowEmailUpdateForm,
        // UpdateEmail:UpdateEmail,
        // ResendOTPUpdatedEmailLoader:ResendOTPUpdatedEmailLoader,
        // setUpdateEmail:setUpdateEmail,
        // setShowEmailUpdateForm:setShowEmailUpdateForm,
        // setShowConfirmEmailForm:setShowConfirmEmailForm,
        setAuthMessage:setAuthMessage,
        handleLogin:handleLogin,
        handleLogOut:handleLogOut,
        signInWithGoogle:signInWithGoogle,
        signInWithFacebook:signInWithFacebook,
        signInWithAmazon:signInWithAmazon,
        signInWithApple:signInWithApple,
        handleSignUp:handleSignUp,
        checkUserAuthenticated:checkUserAuthenticated,
        resendOTP:resendOTP,
        confirmEmail:confirmEmail,
        handleUserName:handleUserName,
        handleEmailChange:handleEmailChange,
        handleForgotPassword:handleForgotPassword,
        handleResendOTPForForgotPassword:handleResendOTPForForgotPassword,
        handleResetPassword:handleResetPassword,
        handleChangePassword:handleChangePassword,
        // getUserProfile:getUserProfile,
        // updateUserEmail:updateUserEmail,
        // verifiyUpdatedEmail:verifiyUpdatedEmail,
        // resendOTPToUpdatedEmail:resendOTPToUpdatedEmail,
        
    }
  return (
    <AuthContext.Provider value={ContextData}>
    {children}
   </AuthContext.Provider>
  )
  }
   