import moment from "moment/moment";
import { useContext, useEffect } from "react";
import { createContext,useState } from "react";

import AuthContext from "./AuthContext";
import { API,Auth} from "aws-amplify";
import awsConfig from "../aws-config";
API.configure(awsConfig);

const ShareContext=createContext();
export default ShareContext;


export const ShareContextProvider = ({children}) => {
    
    const {UserName,UserId}=useContext(AuthContext);    
    const [CommunityContentData,setCommunityContentData]=useState([]);
    const [CommunityContentLoader,setCommunityContentLoader]=useState(false);
    const [UpdateTemplateUseAndRatingLoader,setUpdateTemplateUseAndRatingLoader]=useState(false);
    const [IsSpeak2Admin,setIsSpeak2Admin]=useState(false);
    const [Speak2UserEmailAsOrganizationId,setSpeak2UserEmailAsOrganizationId]=useState(null);
    const NumberOfPagesToDisplayOnPagination=5;
    const [PageSize,setPageSize]=useState(15);
    const [CurrentPage,setCurrentPage]=useState(1);
    const [TotalNumberOfDesignTemplates,setTotalNumberOfDesignTemplates]=useState(15);
    const [IsLoadedAllCommunityContentTemplates,setIsLoadedAllCommunityContentTemplates]=useState(false);
    const [CommunitiesList,setCommunitiesList]=useState(null);
    useEffect(()=>{
        getUserOrganizationEmail();
        getCorporateGroupAndCommunitiesList();
    },[])
    
    // Get Community Content 
    const getCommunityContentTemplates=async(currentpage=1)=>{
        setCommunityContentLoader(true)
        try {
          let organizationEmailId=Speak2UserEmailAsOrganizationId;
            if(organizationEmailId===null)
            {
            organizationEmailId=await getUserOrganizationEmail();
            }
          let communitieslist=CommunitiesList;
          if(communitieslist===null)
          {
            communitieslist=await getCorporateGroupAndCommunitiesList();
          }
           await getUserRole();
            setCommunityContentLoader(true);
          const response = await API.get('Community-Content-Templates', '/community-content', {
            response: true,
            queryStringParameters: {
              page: currentpage,
              limit : IsLoadedAllCommunityContentTemplates===true?15:PageSize,
            }
          });
            // console.log("Data for images is ",JSON.stringify(response.data));
            let filteredCommunityContent=response.data.data;
            setTotalNumberOfDesignTemplates(response.data.totalTemplates);
            if(IsLoadedAllCommunityContentTemplates===true)
            {
              setIsLoadedAllCommunityContentTemplates(false);
              setPageSize(15);
            }
           
              filteredCommunityContent=response.data.data.filter((template)=>{
                if(organizationEmailId!==null)
                {
                  console.log("I am here for "+template.OrganizationSharedBy);
                  if(template.OrganizationSharedBy===null)
                  {
                    return true;
                  }
                  return (template.OrganizationSharedBy!==undefined&&template.OrganizationSharedBy.split('@')[1]===Speak2UserEmailAsOrganizationId.split('@')[1]) || template.IsPublic===true || template.IsPublic===undefined;
                }
                if(template.IsPublic && template.IsPublic===false && template.SharedToCommunitiesList && communitieslist!==null)
                {
                  // here give me code to check communitieslist have any of its string exists in  template.SharedToCommunitiesList if so then return that template otherwise exlude this tempalte
                  return communitieslist.some((community) => 
                    template.SharedToCommunitiesList.includes(community)
                );
                }
                console.log("below the belt "+template.OrganizationSharedBy);
                return template.IsPublic===true || template.IsPublic===undefined;
              });
            
            setSpeak2UserEmailAsOrganizationId(organizationEmailId);
        setCommunityContentData(filteredCommunityContent);
          setCommunityContentLoader(false);
        } catch (error) {
            setCommunityContentLoader(false);
          console.log("error fetching design templates",error);
        }
        setCommunityContentLoader(false);
      }
    

      const loadAllCommunityContentTemplates=async()=>{
        setCommunityContentLoader(true)
        var FilteredCommunityTemplates=[];
        let pagesize=PageSize;
        let responcesize=PageSize;
        let pagenumber=1;
        let totalTemplates=0;

        let communitieslist=CommunitiesList;
        if(communitieslist===null)
        {
          communitieslist=await getCorporateGroupAndCommunitiesList();
        }
        while(responcesize===pagesize)
        {
        try {
          if(CommunityContentLoader===false)
          {
            setCommunityContentLoader(true);
          }
          
          let organizationEmailId=Speak2UserEmailAsOrganizationId;
            // let isadmin=IsSpeak2Admin;
          const response = await API.get('Community-Content-Templates', '/community-content', {
            response: true,
            queryStringParameters: {
              page: pagenumber,
              limit : pagesize,
              // add more query params as needed
            }
          });
            // console.log("Data for images is ",JSON.stringify(response.data));
            let filteredCommunityContent=response.data.data;
            responcesize=filteredCommunityContent.length;
            totalTemplates=response.data.totalTemplates
           
              filteredCommunityContent=response.data.data.filter((template)=>{
                if(organizationEmailId!==null)
                {
                  if(template.OrganizationSharedBy===null)
                  {
                    return true;
                  }
                  return (template.OrganizationSharedBy!==undefined&&template.OrganizationSharedBy.split('@')[1]===Speak2UserEmailAsOrganizationId.split('@')[1]) || template.IsPublic===true || template.IsPublic===undefined;
                }
                if(template.IsPublic && template.IsPublic===false && template.SharedToCommunitiesList && communitieslist!==null)
                  {
                    // here give me code to check communitieslist have any of its string exists in  template.SharedToCommunitiesList if so then return that template otherwise exlude this tempalte
                    return communitieslist.some((community) => 
                      template.SharedToCommunitiesList.includes(community)
                  );
                  }
                return template.IsPublic===true || template.IsPublic===undefined;
              });
            
            FilteredCommunityTemplates=FilteredCommunityTemplates.concat(filteredCommunityContent);
          
        } catch (error) {
          console.log("error fetching design templates",error);
          break;
        }
        pagenumber++;
        }
        
        
          // console.log(pagenumber+"We have fetched all of the templates"+totalTemplates+" but total templates are"+FilteredCommunityTemplates.length);
          setCommunityContentData(FilteredCommunityTemplates);
          setPageSize(FilteredCommunityTemplates.length);
          setTotalNumberOfDesignTemplates(FilteredCommunityTemplates.length);
          setIsLoadedAllCommunityContentTemplates(true);
          setCurrentPage(1);
        setCommunityContentLoader(false);
      }
     


    const handleShareTemplate=async(templatename,templatetags,templatetype,templateimage,templatedata,IsSharedTemplatePublic,OrganizationSharedBy)=>{
        // console.log("tempalte name is"+templatename+" and template tags are"+templatetags+"tempalte Type is"+templatetype);
        // console.log("\n Tempalte iamge is"+templateimage);
        // console.log("\n Template data is "+templatedata);
        const formattedDate = moment().format('YYYY-MM-DD');
        // console.log("\n User Name "+UserName);
        let communitiesList=CommunitiesList;
        if(communitiesList===null)
        {
          communitiesList=await getCorporateGroupAndCommunitiesList();
        }
        let SharedToCommunitiesList=[];
        if(IsSharedTemplatePublic===false)
        {
          SharedToCommunitiesList=communitiesList || [];
        }
        let sharedTemplate={
            TemplateId:CommunityContentData.length+1,
            TemplateName:templatename,
            CreatedBy:UserName,
            CreatedDate:formattedDate,
            TagsList:templatetags,
            TemplateType:templatetype,
            TemplateImage:templateimage,
            TemplateData:templatedata,
            TemplateRating:null,
            RatedUsersIDs:[],
            TemplateUsedNumber:0,
            UserId:UserId,
            IsPublic:IsSharedTemplatePublic,
            SharedToCommunitiesList:SharedToCommunitiesList,
            OrganizationSharedBy:OrganizationSharedBy,
        };
        let tempaltedata=await handleStoreSharedTemplate(sharedTemplate);
        let updateddata=sharedTemplate;
        if(tempaltedata!==false)
        {
            updateddata={...sharedTemplate,TemplateId:tempaltedata.TemplateId,TemplateImage:tempaltedata.TemplateImage}
        }
        
        let communitycontent=CommunityContentData;
        communitycontent.push(updateddata);
        setCommunityContentData(communitycontent);
        setCommunityContentLoader(false);
        return true;
    }

/*
    # handle Store Template in Db
*/
    const handleStoreSharedTemplate=async(Tempaltedata)=>{
        setCommunityContentLoader(true);
        let data=[];
        data.push(Tempaltedata);
        let RequestBody={
          Data:data
        };
      
        try {
          const response = await API.post('Community-Content-Templates', '/community-content', {
            body:RequestBody,
            response: true,
          });
      //  console.log("operation Successfull"+response.data);

          return response.data.data;
          //  setLocalStore(response.data.data);
        } catch (error) {
          console.log("Error sharing the template",error);
          // setLocalStore([]);
           return false;
    }
}
/*
# handle Update Tempalte USed  and Rating 
*/
const handleUpdateTemplateUsedAndRating=async(Tempaltedata)=>{
    setUpdateTemplateUseAndRatingLoader(true);
    let data=[];
    data.push(Tempaltedata);
    let RequestBody={
      Data:data,
      TemplateId:Tempaltedata.TemplateId,
    };
    try {
      const response = await API.put('Community-Content-Templates', '/community-content', {
        body:RequestBody,
        response: true,
      });
      setUpdateTemplateUseAndRatingLoader(false);
    return true;
      //  setLocalStore(response.data.data);
    } catch (error) {
      console.log(error);
      // setLocalStore([]);
      setUpdateTemplateUseAndRatingLoader(false);
       return false;
}
}

// handle Detlete Community Content Tempalte
const handleDeleteCommunityContentBackend=async(Tempaltedata)=>{

  let RequestBody={
    TemplateId:Tempaltedata.TemplateId
  };

  setUpdateTemplateUseAndRatingLoader(true);
  try {
    const response = await API.del('Community-Content-Templates', '/community-content', {
      body:RequestBody,
      response: true,
    });
    setUpdateTemplateUseAndRatingLoader(false);
    console.log("operation Successfull"+response.data);
    return true;
    //  setLocalStore(response.data.data);
    
  } catch (error) {
    console.log(error);
    // setLocalStore([]);
    setUpdateTemplateUseAndRatingLoader(false);
     return false;
  }
 }

     // get User Role 
     const getUserRole=async()=>{
      setCommunityContentLoader(true);
      const user=await Auth.currentAuthenticatedUser();
      const RequestBody={
       username: user.username,
      }
       try {
         let response=await API.post('lambda', '/users/profile/get', {
          body:RequestBody,
          response: true,
        });
        if(response.data.Success===true&&response.data.Content.Roles[0].Name==="Facility Admin" &&response.data.Content.Roles[0].IsActive===1)
        { 
          setIsSpeak2Admin(true);
          setCommunityContentLoader(false);
          return true;
        }
        setIsSpeak2Admin(false);
        setCommunityContentLoader(false);
        return false;
      } catch (error) {
        console.log(error);
        setIsSpeak2Admin(false);
        setCommunityContentLoader(false);
        return false;
      }
     };

     // get Corporate Group and Communities List 
    const getCorporateGroupAndCommunitiesList=async()=>{
      setCommunityContentLoader(true)
      try {         
          setCommunityContentLoader(true);
        const response = await API.get('lambda', '/corporates/list');
        const allFacilities = response.Content.map(item => item.Facilities).reduce((acc, facilities) => acc.concat(facilities), []);
        setCommunityContentLoader(false);
        setCommunitiesList(allFacilities);
        return allFacilities;
      } catch (error) {
          setCommunityContentLoader(false);
          setCommunitiesList(null);
        console.log("error Fetching Corporate Group and Communities List",error);
        return null;
      }
    };

     const getUserOrganizationEmail=async()=>{
      const user=await Auth.currentAuthenticatedUser();
      const RequestBody={
       username: user.username,
      }
       try {
         let response=await API.post('lambda', '/users/profile/get', {
          body:RequestBody,
          response: true,
        });
        
        if(response.data.Success===true)
        { 

          var emailDomain = response.data.Content.Email.split('@')[1];
          var commonEmailDomains = [
            'gmail.com',
            'yahoo.com',
            'hotmail.com',
            'outlook.com',
            'aol.com',
            'icloud.com',
            'protonmail.com',
            'zoho.com',
            'yandex.com',
          ];
          console.log("Email Domain is "+emailDomain);
          if(commonEmailDomains.indexOf(emailDomain)===-1)
          {
            console.log("User email is corporate email");
            setSpeak2UserEmailAsOrganizationId(response.data.Content.Email);
            return response.data.Content.Email;
          }
          else
          {
            console.log("User email is not corporate email");
            setSpeak2UserEmailAsOrganizationId(null);
            return null;
          }
          
       
        }
        return null;
      } catch (error) {
        console.log(error);
        setSpeak2UserEmailAsOrganizationId(null);
      return null;
      }
     };
    let ContextData={
        CommunityContentData:CommunityContentData,
        CommunityContentLoader:CommunityContentLoader,
        UpdateTemplateUseAndRatingLoader:UpdateTemplateUseAndRatingLoader,
        IsSpeak2Admin:IsSpeak2Admin,
        NumberOfPagesToDisplayOnPagination:NumberOfPagesToDisplayOnPagination,
        PageSize:PageSize,
        TotalNumberOfDesignTemplates:TotalNumberOfDesignTemplates,
        CurrentPage:CurrentPage,
        IsLoadedAllCommunityContentTemplates:IsLoadedAllCommunityContentTemplates,
        CommunitiesList:CommunitiesList,
        setCurrentPage:setCurrentPage,
        getUserRole:getUserRole,
        getUserOrganizationEmail:getUserOrganizationEmail,
        setUpdateTemplateUseAndRatingLoader:setUpdateTemplateUseAndRatingLoader,
        setCommunityContentData:setCommunityContentData,
        handleShareTemplate:handleShareTemplate,
        handleUpdateTemplateUsedAndRating:handleUpdateTemplateUsedAndRating,
        handleDeleteCommunityContentBackend:handleDeleteCommunityContentBackend,
        Speak2UserEmailAsOrganizationId:Speak2UserEmailAsOrganizationId,
        getCommunityContentTemplates:getCommunityContentTemplates,
        loadAllCommunityContentTemplates:loadAllCommunityContentTemplates,
    }
  return (
    <ShareContext.Provider value={ContextData}>
    {children}
   </ShareContext.Provider>
  )
  }
   