import React from 'react'

const Footer = () => {
  return (
    <div className='flex flex-col space-y-4 items-center font-specialElite'>
    <div  className=' text-center mx-[25%]'>
      <span className='text-center block font-light'>
      Prntyr is brought to you by Speak2. Our belief is that your time is far too valuable to spend worrying about newsletter layouts, calendars and creating flyers and slides to print. Our mission is to provide a way for everyone in the broader community to share their ideas so that we can benefit from each other’s hard work, and get some of our time back.
      </span>
      <span className='mt-4 block font-light'>
        <a target='_blank' rel='noreferrer' href="http://www.speak2family.com/">www.speak2family.com</a>
      </span>
    </div>
  
  <div className='p-8 '>
    <div  className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 font-light'>
      <div>
        <a href="https://www.speak2family.com/privacy-policy/"  target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
      </div>
      <div><a href="https://www.speak2family.com/terms/" target="_blank" rel="noopener noreferrer" > Terms of Use</a></div>
      <div><span> Copyright@speak2family2023</span></div>
      
    </div>
    </div>
    </div>
  )
}

export default Footer