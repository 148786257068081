import React from 'react'
import Logo from '../../AppAssets/Logo.png';
const Header = () => {
  return (
    <div className='w-full font-specialElite'>
      <div className='flex flex-col items-center mt-4 space-y-2     border-b border-black  '>
                <img src={Logo} className="w-[170px] h-[50px]" alt='Print Center' />
                <span className='text-xl font-medium '> (pronounced printer)</span>
      </div>
    <div className='flex flex-col space-y-4 items-center mt-4 '>
         
          <div>
          <span className='text-xl font-light '>Create and print calendars, documents, signage, newsletters and more. </span>
          </div>
    
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
      <div className=' space-y-4 rounded-lg p-4 my-2 w-[281px] text-justify ' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-xl font-semibold block text-center my-1'>About Prntyr</span>
        <span className='text-lg font-light  '>
        Why the spelling error? Because every startup has to have a misspelled name.
        </span>
        </div>
        <div className=' space-y-4 rounded-lg p-4 my-2 w-[281px] text-justify ' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-xl font-semibold block text-center my-1'>Templates & Designs</span>
        <span className='text-lg font-light '>Check out our prebuilt designs ready to use or create your own and share wih your peers.</span>
        </div>
        <div className=' space-y-4 rounded-lg p-4 my-2 w-[281px] text-justify  ' style={{backgroundColor:"#ffff",color:"#404040",border:"solid 3px #E7E7E7"}}>
        <span className='text-xl font-semibold block text-center my-1'>No Cost</span>
        <span className='text-lg font-light ' >
        Create your free account by clicking here. Our goal is to make everyone’s day better for free.  
        </span>
        </div>
      </div>
      
    </div>
    </div>
  )
}

export default Header