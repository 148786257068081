
import { unstable_HistoryRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from './Layout/AppLayout';
import LandingPage from './Pages/LandingPage';
import { Suspense,lazy} from 'react';
import '@fontsource/lato';
import Loader from './AppAssets/Loader.gif';
import ForgotPassword from './Pages/ForgotPassword';
import PrivateRoute from './AppRoutes/PrivateRoute';
import { AuthContextProvider } from './AppContext/AuthContext';
import { ShareContextProvider } from './AppContext/ShareContext';
import { ContentContextProvider } from './AppContext/ContentContext';
import { CreatorsSharedContextProvider } from './AppContext/CreatorsSharedTemplateContext';
import { ProfileContextProvider } from './AppContext/ProfileContext';
import ConfirmEmail from './Pages/ConfirmEmail';
import Redirect from './Pages/Redirect';
import ResetPassword from './Pages/ResetPassword';
import Speak2Redirect from './Pages/Speak2Redirect';
import { history } from './RoutingUtils/History.js';







const Community=lazy(() => import('./Pages/Community'));
const CalendarApp = lazy(() => import('./Apps/CalendarApp/CalendarApp'));
const SlideApp = lazy(() => import('./Apps/SlideApp/SlideApp'));
const NewsLetterApp = lazy(() => import('./Apps/NewsLetterApp/NewsLetterApp'));
const UserProfile = lazy(() => import('./Pages/UserProfile'));










function App() {

  
  
  return (
    <div style={{backgroundColor:"#FFFFFF"}}>
    <Router history={history}>
    
      <AuthContextProvider>
      <ProfileContextProvider>
        <ShareContextProvider>
        <ContentContextProvider>
        <CreatorsSharedContextProvider>
    <AppLayout>
      <Suspense fallback={<div className='relative content-center h-screen'><img className='absolute top-1/2 left-1/2 h-24 2-34'  src={Loader} alt="Loading...please wait" /></div>}>
      <Routes>
          <Route  path='/' element={<LandingPage/>}  />
          <Route exact path="/newsletterapp" element={<PrivateRoute><NewsLetterApp /></PrivateRoute>} /> 
          <Route exact path="/slideapp" element={<PrivateRoute> <SlideApp /></PrivateRoute>} />
          <Route exact path="/calendarapp" element={<PrivateRoute><CalendarApp /></PrivateRoute>} />
          <Route exact path="/designs"  element={<PrivateRoute><Community /></PrivateRoute> } />
          <Route exact path='/profile' element={<PrivateRoute><UserProfile/></PrivateRoute>} />
          <Route exact path='/forgotpassword' element={<ForgotPassword/>} />
          <Route exact path='/confirmemail' element={<ConfirmEmail/>} />
          <Route exact path='/redirect' element={<Redirect/>} />
          <Route exact path='/speak2-redirect' element={<Speak2Redirect/>} />
          <Route exact path='/resetpassword' element={<ResetPassword/>} />
      </Routes>
      </Suspense>
      </AppLayout>
      </CreatorsSharedContextProvider>
      </ContentContextProvider>
      </ShareContextProvider>
      </ProfileContextProvider>
      </AuthContextProvider>
    </Router>
    </div>
  );
}

export default App;
